import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MenuFrontComponent } from '../menu-front/menu-front.component';
import { ProviderNamesEnum } from '../../../../enums/provider.names.enum';
import { FrontMenuService } from '../../../../services/menus/front.menu.service';

@Component({
  selector: 'front-container',
  standalone: true,
  imports: [MenuFrontComponent],
  templateUrl: './front-container.component.html',
  styleUrl: './front-container.component.scss',
  providers: [
    { provide: ProviderNamesEnum.MENU_FRONT, useClass: FrontMenuService },
  ],
})
export class FrontContainerComponent implements OnInit, OnChanges {
  protected target: string = 'about';

  public constructor(
    @Inject(ProviderNamesEnum.MENU_FRONT)
    private readonly _menuService: FrontMenuService,
  ) {}

  public ngOnInit() {
    this._menuService.current$.subscribe({
      next: (value) => {
        if (this.target !== value) {
          this.target = value;
        }
      },
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  protected changeTarget(target: string): void {
    if (this.target !== target) {
      this._menuService.current$.next(target);
    }
  }
}
