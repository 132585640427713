import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ProviderNamesEnum } from '../../../../enums/provider.names.enum';
import { FrontMenuService } from '../../../../services/menus/front.menu.service';

@Component({
  selector: 'front-section',
  standalone: true,
  imports: [],
  templateUrl: './front-section.component.html',
  styleUrl: './front-section.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontSectionComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public name!: string;

  @Input({ required: false })
  public enableGoto: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    @Inject(ProviderNamesEnum.MENU_FRONT)
    private readonly _menuService: FrontMenuService,
  ) {}

  public ngOnInit() {
    this._menuService.current$.subscribe({
      next: (value) => {
        if (value === this.name) {
          this._scrollToSelf();
        }
      },
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  private _scrollToSelf(): void {
    if (this.enableGoto) {
      const element: HTMLElement = this._self.nativeElement as HTMLElement;

      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
}
