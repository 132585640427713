import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'admin-container',
  standalone: true,
  imports: [],
  templateUrl: './admin-container.component.html',
  styleUrl: './admin-container.component.scss',
})
export class AdminContainerComponent implements OnInit, OnChanges {
  public constructor() {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
