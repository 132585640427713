import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';
import { getMenuNavs } from '../../../../utils/menu.utils';
import { MenuNavItem, MenuNavItems } from '../../../../types/types';
import { PlatformIdService } from '@ckmk/angular';

@Component({
  selector: 'menu-front',
  standalone: true,
  imports: [FontAwesomeModule, NgClass],
  templateUrl: './menu-front.component.html',
  styleUrl: './menu-front.component.scss',
})
export class MenuFrontComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public target!: string;

  @Output('onChangeTarget')
  public changeTargetEmitter: EventEmitter<string> = new EventEmitter<string>();

  protected siteName: string = environment.SITE_NAME;

  protected icons = {
    close: faXmark,
    burger: faBars,
  };

  protected navItems: MenuNavItems = getMenuNavs();

  protected opened: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}

  protected toggleOpen(): void {
    this.opened = !this.opened;

    this._toggleAbleCropBody();
  }

  protected isOpened(): boolean {
    return this.opened || this._isBiggerThenPhoneScreen();
  }

  protected isTarget(nav: MenuNavItem): boolean {
    return this.target === nav.name;
  }

  protected changeTarget(nav: MenuNavItem): void {
    if (!this._isBiggerThenPhoneScreen()) {
      if (this.opened) {
        this.opened = false;

        this._toggleAbleCropBody();
      }
    }

    this.changeTargetEmitter.emit(nav.name);
  }

  private _isBiggerThenPhoneScreen(): boolean {
    return (
      this._platformIdService.isBrowser() && document.body.offsetWidth >= 768
    );
  }

  private _toggleAbleCropBody(): void {
    if (this._platformIdService.isBrowser()) {
      if (this.opened) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    }
  }
}
