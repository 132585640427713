<ng-container>
  <div
    class="
      menu-front-container bg-white overflow-y-auto overflow-x-hidden z-50
      md:inset-auto md:sticky md:top-0 md:left-0 md:flex md:w-full md:items-center md:justify-center
    "
    [ngClass]="{
      'fixed inset-0': opened,
      'sticky top-0 left-0': !opened,
    }"
  >
    <div
      class="
        menu-front-content w-full
        flex flex-col
        md:flex-row md:items-center md:justify-between md:container
        md:max-w-[90%]
        xl:max-w-[80%]
      "
    >
      <div
        [title]="siteName"
        class="
          logo w-full bg-white
          flex flex-row items-center justify-between p-3 sticky top-0 left-0 z-10
        "
      >
        <div
          class="
            logo-img max-w-[170px]
          "
        >
          <img
            class="
            block w-full
          "
            src="/img/logo_website.png"
            [alt]="siteName"
          />
        </div>

        <button
          class="
            inline-flex items-center justify-center py-2 px-1 pr-2 text-4xl
            md:hidden
          "
          (click)="toggleOpen()"
        >
          <fa-icon [icon]="opened ? icons.close : icons.burger"/>
        </button>
      </div>

      <div
        class="
          menu-items-container
          flex flex-col mt-6
          md:flex-row md:items-center md:mt-0
        "
        [ngClass]="{
          'hidden': !isOpened(),
          'block': isOpened(),
        }"

      >
        <div
          class="
            menu-navbar
            flex flex-col w-full gap-1 py-1 bg-gray-100 mb-6
            md:bg-transparent md:flex-row md:mb-0
          "
        >
          @for (nav of navItems; track $index) {
            <button
              [title]="nav.label"
              class="
                nav-item
                block text-2xl py-3 px-2 select-none
                md:hover:bg-black md:hover:text-white
                md:text-[14px] md:py-1 md:font-bold md:rounded
              "
              [ngClass]="{
                'bg-black text-white pointer-events-none': isTarget(nav),
                'bg-white': !isTarget(nav),
              }"
              (click)="changeTarget(nav)"
            >
              {{ nav.label }}
            </button>
          }
        </div>

        <div
          class="
            network
            flex w-full flex-row items-center justify-between py-5 m-auto gap-4 max-w-[60%]
            md:py-0 md:m-0 md:border-l md:border-l-gray-300 md:pl-4 md:gap-2 md:ml-2
          "
        >
          <a
            href="#"
            class="
              block
              w-[40px]
              md:w-[16px]
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
            </svg>
          </a>

          <a
            href="#"
            class="
              block
              w-[40px]
              md:w-[16px]
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/>
            </svg>
          </a>

          <a
            href="#"
            class="
              block
              w-[40px]
              md:w-[16px]
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
            </svg>
          </a>

          <a
            href="#"
            class="
              block
              w-[40px]
              md:w-[16px]
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
