<ng-container>
  @if (isAdminRoute()) {
    <admin-container>
      <router-outlet/>
    </admin-container>
  } @else {
    <front-container>
      <router-outlet/>
    </front-container>
  }
</ng-container>
