import { MenuNavItems } from '../types/types';

export const getMenuNavs = (): MenuNavItems => [
  { name: 'about', label: 'About' },
  { name: 'news', label: 'News' },
  { name: 'programs', label: 'Programs' },
  { name: 'gallery', label: 'Gallery' },
  { name: 'agenda', label: 'Agenda' },
  { name: 'partners', label: 'Partners' },
  { name: 'contact', label: 'Contact' },
];
