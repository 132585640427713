<ng-container>
  <front-section [name]="'news'" [enableGoto]="true" class="bg-black">
    <headline [label]="'News'" class="headline-news" />

    @defer (when news && news.length > 0) {
      <div
        class="
          w-full flex flex-col gap-8
          md:flex-row md:items-start md:justify-between
        "
      >
        @for (newItem of news; track $index) {
          <new-item-card [item]="newItem"/>
        }
      </div>
    }
  </front-section>
</ng-container>
