import { Routes } from '@angular/router';
import { HomeComponent } from './modules/front/views/home/home.component';
import { NotFoundComponent } from './modules/front/views/not-found/not-found.component';
import { authCanActivateHash, authPathMatcher } from '@ckmk/angular';
import { environment } from '../environments/environment';
import { LoginComponent } from './modules/admin/views/login/login.component';
import { DashboardComponent } from './modules/admin/views/dashboard/dashboard.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  {
    matcher: authPathMatcher(),
    canActivate: [authCanActivateHash(environment.ADMIN_HASH)],
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];
